<template>
    <div class="row">
        <div class="col-2">
            <b-form-group description="Внешний вид">
                <select v-model="item.has_appearance" class="form-control">
                    <option :value="true">Соответствует</option>
                    <option :value="false">Не соответствует</option>
                </select>
            </b-form-group>
        </div>
        <div class="col-2">
            <b-form-group description="Цвет">
                <select v-model="item.has_color" class="form-control">
                    <option :value="true">Соответствует</option>
                    <option :value="false">Не соответствует</option>
                </select>
            </b-form-group>
        </div>
        <div class="col-2">
            <b-form-group description="Запах">
                <select v-model="item.has_smell" class="form-control">
                    <option :value="true">Соответствует</option>
                    <option :value="false">Не соответствует</option>
                </select>
            </b-form-group>
        </div>
        <div class="col-2">
            <b-form-group description="Консистенция">
                <select v-model="item.has_consistence" class="form-control">
                    <option :value="true">Соответствует</option>
                    <option :value="false">Не соответствует</option>
                </select>
            </b-form-group>
        </div>
        <div class="col-2">
            <b-form-group description="Сопроводительная документация">
                <select v-model="item.has_supporting_documentation" class="form-control">
                    <option :value="true">Соответствует</option>
                    <option :value="false">Не соответствует</option>
                </select>
            </b-form-group>
        </div>
        <div class="col-2">
            <b-form-group description="Соответствует">
                <select v-model="item.is_correspond" class="form-control">
                    <option :value="true">Соответствует</option>
                    <option :value="false">Не соответствует</option>
                </select>
            </b-form-group>
        </div>
    </div>
</template>

<script>
export default {
    name: "QualityControlPicker",
    props: {
        item: Object,
    },
    watch: {
        'item.is_correspond'(value) {
            if (value === true) {
                this.item.has_appearance = value;
                this.item.has_color = value;
                this.item.has_smell = value;
                this.item.has_consistence = value;
                this.item.has_supporting_documentation = value;
            }
        },
    },
}
</script>
