<template>
    <div style="padding-bottom: 50px;" class="act-form admission-act-form">
        <error-list :errors="errors" :message-function="errorMessage" @clear="errors = []"></error-list>
        <b-alert :show="errorCountDown" dismissible variant="danger" @dismissed="errorCountDown = 0">
            {{ countDownableError }}
        </b-alert>

        <template slot="title">
            <i class="fas fa-exclamation-circle text-danger" v-show="act && !isFirstTabValid"></i> Накладная
        </template>
        <b-card v-if="act">
            <b-row v-if="act.orderToContractorActs">
                <b-col cols="6">
                    <b-form-group>
                        <template v-for="(order, index) in act.orderToContractorActs">
                            <router-link
                                :to="{
                                    name: 'OrderToContractorActForm',
                                    params: { id: order.id }
                                }"
                            >Заказ №{{ order.id }}
                            </router-link>
                            {{ index < act.orderToContractorActs.length - 1 ? ', ' : '' }}
                        </template>
                    </b-form-group>
                </b-col>
                <b-col v-if="isOrderOfBrandKitchen" cols="6" class="text-right">
                    <h4>
                        <b-badge variant="warning">Заказ бренд кухня</b-badge>
                    </h4>
                </b-col>
            </b-row>
            <div class="row">
                <div class="col-6">
                    <b-form-group description="Принять на склад">
                        <storage-picker :material="true" :allowed-roles="[ROLE_STOCKMAN, ROLE_QUALITY_CONTROL]"
                                        :disabled="disabled" v-model="act.storage"></storage-picker>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <b-form-group description="Поставщик">
                        <contractor-input :disabled="disabled" v-model="act.contractor"></contractor-input>
                    </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <b-form-group description="Вход. номер">
                        <b-input-group prepend="#">
                            <input v-model="act.incoming_number" :disabled="disabled" type="text" class="form-control"
                                   placeholder="Вход. номер">
                        </b-input-group>
                    </b-form-group>
                </div>
                <div class="col-3">
                    <b-form-group description="Вход. дата" style="margin-bottom: 0;">
                        <b-input-group>
                            <date-picker
                                lang="ru"
                                type="date"
                                format="DD.MM.YYYY"
                                value-type="YYYY-MM-DD"
                                placeholder="Выберите дату"
                                v-model="act.incoming_date"
                                key="incoming_date"
                                id="incoming_date"
                            />
                        </b-input-group>
                    </b-form-group>
                </div>
                <div class="col-2">
                    <b-form-group description="Температура в транспорте">
                        <input v-model.number="act.aboard_temperature" type="number" class="form-control">
                    </b-form-group>
                </div>
                <div class="col-4">
                    <b-form-group description="Ответственный">
                        <user-input v-model="act.technologist"></user-input>
                    </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b-form-group description="Комментарий">
                        <textarea class="form-control" :disabled="disabled" v-model="act.comment"></textarea>
                    </b-form-group>
                </div>
            </div>
            <b-row>
                <b-col cols="12">
                    <b-form-group description="Комментарий отмены">
                        <textarea class="form-control" :disabled="disabled" v-model="act.comment_of_cancel"></textarea>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>

        <nomenclature-list
            v-model="selectedItem"
            v-if="act"
            :items="act.items"
            :storage="act.storage"
            :disabled="disabled"
            ref="nomenclatureList"
            @remove="removeItem"
            @copy="copyItem"
        />
        <find-component
            :disabled="disabled"
            :addItem="addItem"
            v-if="act && !act.status"
        />
        <b-alert v-else show variant="warning">
            <b-icon-exclamation-circle-fill/>
            <span
                v-if="act && act.status && ([CANCELED_STATUS, ACCEPTED_STATUS].includes(act.status.code) || act.canceled)"
            >
                Нельзя добавить номенклатуру в принятый/отмененный акт снабжения
            </span>
            <span v-else>
                Добавить номенклатуру возможно только на стадии "Оформления"
                <b-button variant="danger" @click="changeStatus(null)">На стадию оформления</b-button>
            </span>
        </b-alert>

        <success-message v-model="successCountDown"></success-message>

        <control-panel v-if="act">
            <b-button variant="danger" @click="$router.go(-1)">Выйти</b-button>
            <template v-if="currentActId">
                <b-dropdown variant="warning" text="🖨" v-if="isWebPrintMode">
                    <b-dropdown-item :to="{name: 'LabelPrint', query: {admission_act_id: currentActId}}">
                        Все
                    </b-dropdown-item>
                    <b-dropdown-item :to="{name: 'LabelPrint', query: {admission_act_item_id: selectedItem.id}}"
                                     v-if="selectedItem && selectedItem.id"
                    >
                        Текущая
                    </b-dropdown-item>
                </b-dropdown>

                <b-dropdown variant="warning" text="🖨" v-if="isNiceLabelPrintMode">
                    <b-dropdown-item v-b-modal.nicelabel-full-print-modal>
                        Все
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-if="selectedItem && selectedItem.id"
                        v-b-modal.nicelabel-current-print-modal
                    >
                        Текущая
                    </b-dropdown-item>
                </b-dropdown>
            </template>

            <b-button
                v-show="act.status"
                variant="danger"
                @click="cancelOrRevert"
            >
                {{ act.canceled ? 'Восстановить' : 'Отменить' }}
            </b-button>

            <template v-if="notCanceled">
                <b-button
                    v-show="!act.status"
                    variant="success"
                    @click="changeStatus(ISSUED_STATUS)"
                    :disabled="disabled"
                >
                    Оформить
                </b-button>

                <b-button
                    v-show="canVerifie"
                    variant="warning"
                    @click="changeStatus(VERIFIED_STATUS)"
                    :disabled="disabled"
                >
                    Проверен
                </b-button>

                <b-button
                    v-show="canAccept"
                    variant="success"
                    @click="changeStatus(ACCEPTED_STATUS)"
                    :disabled="disabled"
                >
                    Принять
                </b-button>
            </template>
        </control-panel>

        <users-of-the-act :act="act"></users-of-the-act>

        <b-modal
            centered
            hide-footer
            id="nicelabel-current-print-modal"
            ref="nicelabel-current-print-modal"
            class="print-current-nomenclature-lot-modal"
            @hide="actionsAfterHideModalNiceLabelCurrentPrint"
            @show="actionsBeforeShowModalNiceLabelCurrentPrint"
        >
            <b-form-group label="Принтер">
                <printer-picker v-model="printer" :for-material-storage="true"/>
            </b-form-group>
            <b-row>
                <b-col cols="12">
                    <b-alert v-if="errorLabelsPrint !== null" show variant="danger">
                        <b>{{ errorLabelsPrint }}</b>
                    </b-alert>
                </b-col>
            </b-row>
            <b-form-group label="Введите количество этикеток:">
                <b-input
                    v-model="numberOfRequiredLabels"
                    type="number"
                    placeholder="Введите количество"
                />
            </b-form-group>
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button variant="primary" @click="niceLabelCurrentPrint">Отправить на печать</b-button>
                </b-col>
            </b-row>
        </b-modal>

        <b-modal
            centered
            hide-footer
            id="nicelabel-full-print-modal"
            ref="nicelabel-full-print-modal"
            @hide="errorLabelsPrint=null"
            @show="errorLabelsPrint=null"
        >
            <b-form-group label="Принтер">
                <printer-picker v-model="printer" :for-material-storage="true"/>
            </b-form-group>
            <b-row>
                <b-col cols="12">
                    <b-alert v-if="errorLabelsPrint !== null" show variant="danger">
                        <b>{{ errorLabelsPrint }}</b>
                    </b-alert>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button variant="primary" @click="niceLabelFullPrint">Отправить на печать</b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import {
    NOMENCLATURE_OBJECT_TYPE,
    STORAGE_PLACE_OBJECT_TYPE
}                             from "@utils/objectTypes";
import {
    ADMISSION_ACTS_RESOURCE_ENDPOINT,
    PRINT_QUEUES,
    ORDER_TO_CONTRACTOR_ACTS_ENDPOINT,
    CONTRACTORS_ENDPOINT,
    NOMENCLATURE_LOTS_ENDPOINT,
}                             from "@utils/endpoints";
import NomenclatureList       from "./components/NomenclatureList";
import FindComponent          from "./components/FindComponent";
import BarcodeRepository      from '@utils/BarcodeRepository.js';
import moment                 from 'moment';
import {findIndex, cloneDeep} from 'lodash';
import ErrorList              from '@components/_common/ErrorList';
import DatePicker             from 'vue2-datepicker';
import StoragePicker          from "@components/_common/StoragePicker";
import ControlPanel           from '@components/_common/ControlPanel';
import SuccessMessage         from '@components/_common/SuccessMessage';
import ContractorInput        from "@components/_common/ContractorInput";
import UsersOfTheAct          from '@components/_common/UsersOfTheAct';
import UserInput              from "@components/_common/UserInput";

import PrinterPicker from '@components/_common/devices/PrinterPicker';

import map                                                                from 'lodash/map';
import {ADMISSION_ACT_PRINT_MODE, PRINT_MODE_WEB, PRINT_MODE_NICELABEL}   from '@utils/printModes';
import {ROLE_STOCKMAN, ROLE_DIRECTOR, ROLE_QUALITY_CONTROL}               from "@utils/Roles";
import {ACCEPTED_STATUS, ISSUED_STATUS, VERIFIED_STATUS, CANCELED_STATUS} from '@utils/act-statuses';

const WORK_POSITION_TECHNOLOGIST = 'technologist';
const WORK_POSITION_TECHNOLOGIST_CALCULATOR = 'technologist_calculator';
const WORK_POSITION_SHIFT_TECHNOLOGIST_1 = 'shift_technologist_1';
const WORK_POSITION_SHIFT_TECHNOLOGIST_2 = 'shift_technologist_2';
const WORK_POSITION_CHIEF_TECHNOLOGIST = 'chief_technologist';
const WORK_POSITION_SENIOR_TECHNOLOGIST = 'senior_technologist';
const WORK_POSITION_SENIOR_TECHNOLOGIST_CALCULATOR = 'senior_technologist_calculator';
const WORK_POSITION_SHIFT_TECHNOLOGIST_TRAINEE = 'shift_technologist_trainee';
const WORK_POSITION_SHIFT_TECHNOLOGIST_TRAINEE_2 = 'shift_technologist_trainee_2';

export default {
    name: 'Form',
    components: {
        ContractorInput,
        DatePicker,
        StoragePicker,
        FindComponent,
        NomenclatureList,
        ErrorList,
        ControlPanel,
        SuccessMessage,
        UsersOfTheAct,
        UserInput,
        PrinterPicker,
    },
    computed: {
        isFirstTabValid() {
            return this.act.contractor
                && this.act.incoming_number
                && this.act.incoming_date
        },
        currentActId() {
            return this.$route.params.id ? parseInt(this.$route.params.id) : null
        },
        isWebPrintMode() {
            return PRINT_MODE_WEB === ADMISSION_ACT_PRINT_MODE;
        },
        isNiceLabelPrintMode() {
            return PRINT_MODE_NICELABEL === ADMISSION_ACT_PRINT_MODE;
        },
        disabled() {
            return (this.currentActId && this.act.blocked) || this.isLoading;
        },

        canVerifie() {
            return this.act
                && this.act.status
                && this.act.status.code === ISSUED_STATUS
                && (
                    this.$auth.user().roles.some(role => [ROLE_DIRECTOR].includes(role))
                    || (
                        this.$auth.user().current_work_position
                        && [
                            WORK_POSITION_TECHNOLOGIST,
                            WORK_POSITION_TECHNOLOGIST_CALCULATOR,
                            WORK_POSITION_SHIFT_TECHNOLOGIST_1,
                            WORK_POSITION_SHIFT_TECHNOLOGIST_2,
                            WORK_POSITION_CHIEF_TECHNOLOGIST,
                            WORK_POSITION_SENIOR_TECHNOLOGIST,
                            WORK_POSITION_SENIOR_TECHNOLOGIST_CALCULATOR,
                            WORK_POSITION_SHIFT_TECHNOLOGIST_TRAINEE,
                            WORK_POSITION_SHIFT_TECHNOLOGIST_TRAINEE_2,
                        ].includes(this.$auth.user().current_work_position.key)
                    )
                );
        },

        canAccept() {
            return this.act
                && this.act.status
                && [VERIFIED_STATUS, ACCEPTED_STATUS].includes(this.act.status.code);
        },

        notCanceled() {
            return this.act
                && (
                    this.act.canceled
                    && this.act.canceled === false
                )
                || !this.act.canceled;
        },
        preparedParams() {
            return {
                with: [
                    'contractor',
                    'orderToContractorItems.nomenclature.measureUnit',
                    'orderToContractorItems.nomenclature.type',
                    'admissionActs',
                    'admissionAct',
                ],
                appends: [
                    'status',
                ],
            }
        },
        isOrderOfBrandKitchen() {
            return this.act
                && this.act.orderToContractorActs
                && this.act.orderToContractorActs[0]
                && this.act.orderToContractorActs[0].is_order_of_brand_kitchen;
        }

    },
    watch: {
        currentActId(newVal, oldVal) {
            if (oldVal) {
                this.resetData()
            }
        }
    },
    data() {
        return {
            ROLE_STOCKMAN,
            ROLE_QUALITY_CONTROL,
            act: null,
            with: [
                'contractor',
                'orderToContractorActs.orderToContractorItems',
                'technologist',
                'items.nomenclature.measureUnit',
                'items.nomenclature.type',
                'items.correctionAction',
                'items.manufacturer',
                'storage',
                'author',
            ],
            selectedItem: null,
            errors: [],
            successCountDown: 0,
            errorCountDown: 0,
            countDownableError: null,
            tabIndex: 0,
            signDiff: false,
            errorLabelsPrint: null,
            numberOfRequiredLabels: null,
            status: null,
            ISSUED_STATUS,
            VERIFIED_STATUS,
            ACCEPTED_STATUS,
            CANCELED_STATUS,
            printer: null,
            isLoading: false,
        }
    },
    beforeRouteLeave(to, from, next) {
        if (!this.act.id && to.name !== 'AdmissionActEdit') {
            // При уходе со страницы запоминаем состояние акта если он был не создан
            localStorage.setItem('AdmissionActList-filter', JSON.stringify(this.act))
        }

        next()
    },
    methods: {
        /**
         * Добавление заданий в очередь печатей
         *
         * @param nomenclatureLotIds
         * @param numberOfDuplicates
         */
        niceLabelPrint(nomenclatureLotIds, numberOfDuplicates = 1) {
            let params = {
                ids: nomenclatureLotIds,
                duplicates: parseInt(numberOfDuplicates),
                printer_id: this.printer ? this.printer.id : null,
            };

            this.$http
                .post(PRINT_QUEUES + '/append-to/nomenclature-lot', params)
                .then(
                    () => {
                        this.$refs['nicelabel-current-print-modal'].hide();
                        this.$refs['nicelabel-full-print-modal'].hide();
                        this.actionsAfterHideModalNiceLabelCurrentPrint();
                        this.$bvToast.toast('Отправлено на печать', {variant: 'success'});
                    },
                    response => {
                        this.errorLabelsPrint = response.body.errors && response.body.errors[0] ? response.body.errors[0] : 'Ошибка во время создания очереди на печать';
                        this.$root.responseError(response);
                    }
                )
        },
        /**
         * Печать всех партий акта
         */
        niceLabelFullPrint() {
            this.actionsAfterHideModalNiceLabelCurrentPrint();

            let params = {
                admission_act_id: this.currentActId,
            };

            this.$http
                .get(NOMENCLATURE_LOTS_ENDPOINT + '/by-admission-act', {params})
                .then(response => {
                    let nomenclatureLotIds = map(
                        response.data.data,
                        nomenclatureLot => nomenclatureLot.id
                    );

                    this.niceLabelPrint(nomenclatureLotIds);
                });
        },
        /**
         * Печать текующей партии акта
         */
        niceLabelCurrentPrint() {
            if (!this.niceLabelCurrentPrintValidation()) {
                return false;
            }

            let params = {admission_act_item_id: this.selectedItem.id};

            this.$http
                .get(NOMENCLATURE_LOTS_ENDPOINT + '/by-admission-act-item', {params})
                .then(response => {
                    let nomenclatureLotIds = map(
                        response.data.data,
                        nomenclatureLot => nomenclatureLot.id
                    );

                    this.niceLabelPrint(nomenclatureLotIds, this.numberOfRequiredLabels);
                });
        },
        /**
         * Валидация печати текущего элемента
         *
         * @return {boolean}
         */
        niceLabelCurrentPrintValidation() {
            if (this.numberOfRequiredLabels === null) {
                this.errorLabelsPrint = 'Необходимо ввести количество этикеток';

                return false;
            }

            if (this.numberOfRequiredLabels <= 0) {
                this.errorLabelsPrint = 'Количество этикеток должно быть больше 0';

                return false;
            }

            return true;
        },
        /**
         * Действия после закрытия модального окна печати текущего элемента
         */
        actionsAfterHideModalNiceLabelCurrentPrint() {
            this.errorLabelsPrint = null;
            this.numberOfRequiredLabels = null;
        },
        /**
         * Действия перед показом модального окна печати текущего элемента
         */
        actionsBeforeShowModalNiceLabelCurrentPrint() {
            this.errorLabelsPrint = null;
            this.numberOfRequiredLabels = null;
        },

        resetData() {
            if (this.currentActId) {
                this.fetchAct()
            } else {
                this.setNewAct()
            }
        },
        fetchAct() {
            this.$http.get(this.getBaseUrl() + '/' + this.currentActId, {
                params: {
                    with: this.with,
                }
            })
                .then(response => {
                    this.act = response.data.data;

                    this.generateIncomingCount();
                })
        },
        setNewAct() {
            let filtersJson = localStorage.getItem('AdmissionActList-filter');
            let storage = null;
            let provider = '';
            let incoming_number = '';
            let incoming_date = moment().format('YYYY-MM-DD');
            let items = [];

            let contractor = null;
            let orderToContractorActs = [];

            if (filtersJson) {
                filtersJson = JSON.parse(filtersJson);
                storage = filtersJson.storage ? filtersJson.storage : storage;
                provider = filtersJson.provider ? filtersJson.provider : provider;
                incoming_number = filtersJson.incoming_number ? filtersJson.incoming_number : incoming_number;
                incoming_date = filtersJson.incoming_date ? filtersJson.incoming_date : incoming_date;
            }

            if (this.$route.params.order_to_contractor_act_ids) {
                this.$route.params.order_to_contractor_act_ids.map(orderId => {
                    orderToContractorActs.push({
                        id: orderId,
                    });

                    this.$http
                        .get(ORDER_TO_CONTRACTOR_ACTS_ENDPOINT + '/' + orderId, {params: this.preparedParams})
                        .then(response => {
                            response.data.data.orderToContractorItems.map(item => {
                                item.exists = false;
                                this.addItem(item);
                            });
                        });
                });
            }

            if (this.$route.params.contractor_id) {
                this.$http
                    .get(CONTRACTORS_ENDPOINT + '/' + this.$route.params.contractor_id)
                    .then(response => this.act.contractor = response.data.data);
            }

            this.act = {
                storage,
                provider,
                incoming_number,
                incoming_date,
                items,
                contractor,
                orderToContractorActs
            }
        },
        getBaseUrl() {
            return ADMISSION_ACTS_RESOURCE_ENDPOINT;
        },
        save() {
            this.errors = [];

            let url = this.getBaseUrl(),
                data = this.getDataForSave();

            this.isLoading = true;
            return new Promise((resolve, reject) => {
                this.$http.post(url, data, {params: {with: this.with}})
                    .then(response => {
                        this.successCountDown = 5;
                        this.act = response.data.data;

                        this.generateIncomingCount();

                        if (!this.currentActId) {
                            localStorage.removeItem('AdmissionActList-filter');
                            this.$router.push({name: 'AdmissionActEdit', params: {id: response.data.data.id}});
                            this.$root.listenToBarcodeScanning(this);
                        }

                        resolve();
                    })
                    .catch(error => {
                        if (error.body.errors) {
                            this.errors = Object.values(error.body.errors).flat();
                        } else {
                            this.errors = [error.body.message];
                        }

                        window.scrollTo({top: 0, behavior: 'smooth'});

                        reject();
                    }).finally(() => this.isLoading = false);
            })
        },
        changeStatus(status) {
            this.status = status;
            if (status) {
                this.save();
            } else if (this.act && this.act.status) {
                this.act.status = status;
            }
        },
        getDataForSave() {
            let items = [];

            this.act.items.forEach((item) => {
                items.push({
                    count: parseFloat(item.delivered_count) - parseFloat(item.returnCount),
                    correction_action_code: (item.correctionAction && item.correctionAction.code) || '',
                    return_count: item.returnCount ? parseFloat(item.returnCount) : 0,
                    expiration_date: item.expirationDate ? moment(item.expirationDate).format('YYYY-MM-DD') : null,
                    manufacture_date: item.manufactureDate ? moment(item.manufactureDate).format('YYYY-MM-DD') : null,
                    nomenclature_id: item.nomenclature.id,
                    container_count: (item.container_count && item.container_measure_unit_id) ? parseFloat(item.container_count) : null,
                    container_measure_unit_id: (item.container_count && item.container_measure_unit_id) ? (item.container_measure_unit_id) : null,
                    storage_place_id: item.storage_place_id ? item.storage_place_id : null,
                    admission_temperature: item.admissionTemperature ? item.admissionTemperature : null,
                    manufacturer: item.manufacturer,
                    comment: !!item.comment ? item.comment : '',
                    certificate_name: item.certificateName,
                    certificate_expires_at: item.certificateExpiresAt ? moment(item.certificateExpiresAt).format('YYYY-MM-DD') : null,
                    has_appearance: item.has_appearance,
                    has_color: item.has_color,
                    has_smell: item.has_smell,
                    has_consistence: item.has_consistence,
                    has_supporting_documentation: item.has_supporting_documentation,
                    is_correspond: item.is_correspond,
                })
            });

            return {
                admission_act_id: this.currentActId,
                act_data: {
                    storage_id: this.act.storage ? this.act.storage.id : null,
                    contractor_id: this.act.contractor ? this.act.contractor.id : null,
                    incoming_date: this.act.incoming_date ? moment(this.act.incoming_date).format('YYYY-MM-DD') : null,
                    incoming_number: this.act.incoming_number,
                    comment: this.act.comment,
                    aboard_temperature: this.act.aboard_temperature,
                    technologist_id: !!this.act.technologist ? this.act.technologist.id : null,
                    status_code: this.status,
                },
                order_to_contractor_act_ids: this.act.orderToContractorActs.map(order => order.id),
                items
            };
        },
        addItem(item, forceNew = false) {
            let index = findIndex(this.act.items, i => {
                let result = i.nomenclature.id === item.nomenclature.id

                if (item.containerCount && item.containerMeasureUnit) {
                    result = result && i.containerMeasureUnit && i.containerMeasureUnit.id === item.containerMeasureUnit.id
                }

                return result;
            })

            item.returnCount = 0;
            if (index === -1 || forceNew) {
                item.id = Math.ceil(Math.random() * 10000000)
                item.exists = false
                if (item.order_to_contractor_act_id) {
                    item.incoming_count = parseFloat(item.count);
                    item.delivered_count = parseFloat(item.count) + parseFloat(item.returnCount);
                }

                this.act.items.push(item)

                this.$refs.nomenclatureList.selectItem(item, true)
            } else {
                this.act.items[index].count = parseFloat(this.act.items[index].count) + parseFloat(item.count)

                if (item.order_to_contractor_act_id) {
                    this.act.items[index].incoming_count = this.act.items[index].count;
                    this.act.items[index].delivered_count = parseFloat(this.act.items[index].count) + parseFloat(item.returnCount);
                }


                if (item.containerCount && item.containerMeasureUnit) {
                    this.act.items[index].containerCount = parseFloat((this.act.items[index].containerCount + item.containerCount).toFixed(10))
                }

                this.$refs.nomenclatureList.selectItem(this.act.items[index], true)
            }
        },
        copyItem(item) {
            let newItem = cloneDeep(item)

            delete newItem.id
            delete newItem.storagePlace
            delete newItem['@id']
            delete newItem.collapsed
            delete newItem._rowVariant

            this.addItem(newItem, true)
        },
        removeItem(item) {
            if (window.confirm('Удалить строку?')) {
                let index = this.act.items.findIndex(i => i === item);

                if (index !== -1) {
                    this.act.items.splice(index, 1)
                }
            }
        },
        onBarcodeScanned(data) {
            // Если шк партии - выводим ошибку
            for (let i = 0; i < BarcodeRepository.nomenclatureLotPrefixes.length; i++) {
                if (data.barcode.startsWith(BarcodeRepository.nomenclatureLotPrefixes[i])) {
                    this.countDownableError = 'Ошибка: штрих код партии!'
                    this.errorCountDown = 8
                    return
                }
            }

            BarcodeRepository.findFirstResourceByBarcode(data.barcode)
                .then(result => {
                    if (!result) {
                        return this.processToNewBarcode(data.barcode)
                    }

                    switch (result.object.objectType) {
                        case NOMENCLATURE_OBJECT_TYPE:
                            this.addItem({
                                nomenclature: result.resource,
                                count: result.resource.measureUnit.is_weighted ? null : result.object.count,
                                containerCount: result.object.containerCount,
                                containerMeasureUnit: cloneDeep(result.object.containerMeasureUnit),
                                showContainerInputs: Boolean(result.object.containerCount && result.object.containerMeasureUnit)
                            })
                            break;

                        case STORAGE_PLACE_OBJECT_TYPE:
                            // && result.resource.storageId === this.act.storage.id // возможно это стоит делать в каком-то другом месте
                            if (this.selectedItem) {
                                this.$set(this.selectedItem, 'collapsed', true)
                                this.$set(this.selectedItem, 'storagePlace', result.resource)
                            }
                            break;
                    }
                })
        },
        processToNewBarcode(barcode) {
            let message = 'Нет ШК! Создать?'

            if (this.currentActId) {
                message += ' (Текущий акт будет сохранен)'
            }

            if (!window.confirm(message)) {
                return
            }

            let routeParams = {
                name: 'BarcodeCreate',
                query: {
                    barcode
                },
                params: {
                    returnTo: {
                        name: this.$route.name,
                        params: {...this.$route.params, tabIndex: 1},
                    }
                }
            }

            if (this.currentActId) {
                this.save()
                    .then(() => {
                        this.$router.push(routeParams)
                    })
            } else {
                this.$router.push(routeParams)
            }
        },
        errorMessage(error) {
            if (typeof error === 'string') {
                return error
            }

            let matchResult = error.propertyPath.match(/^items\[(\d+)]/),
                message = error.message;

            if (matchResult) {
                message = `${this.act.items[matchResult[1]].nomenclature.name}: ${message}`
            }

            return message
        },
        generateIncomingCount() {
            if (this.act.orderToContractorActs.length) {
                const orderToContractActItems = this.act.orderToContractorActs[0].order_to_contractor_items;
                const nomentclatureIds = orderToContractActItems.map((item) => item.nomenclature_id);

                this.act.items = this.act.items.map((item) => {
                    const orderToContractItemIndex = nomentclatureIds.indexOf(item.nomenclature.id);
                    if (orderToContractItemIndex > -1) {
                        return {
                            ...item,
                            incoming_count: parseFloat(orderToContractActItems[orderToContractItemIndex].count),
                            delivered_count: parseFloat(item.count) + parseFloat(item.returnCount)
                        }
                    }
                });
            } else {
                this.act.items = this.act.items.map((item) => {
                    return {
                        ...item,
                        delivered_count: parseFloat(item.count) + parseFloat(item.returnCount)
                    }
                });
            }
        },
        cancelOrRevert() {
            this.errors = [];
            this.$http.put(
                `${ADMISSION_ACTS_RESOURCE_ENDPOINT}/${this.act.id}/cancel`,
                {
                    canceled: !this.act.canceled,
                    comment_of_cancel: this.act.canceled === false ? this.act.comment_of_cancel : ''
                }
            ).then(() => {
                this.act.canceled = !this.act.canceled;
                this.fetchAct();
            }).catch((error) => {
                console.log(error)
                if (error.body.errors) {
                    this.errors = Object.values(error.body.errors).flat();
                } else {
                    this.errors = [error.body.message];
                }

                window.scrollTo({top: 0, behavior: 'smooth'});
            });
        },
    },
    mounted() {
        this.resetData()
        this.$root.listenToBarcodeScanning(this)

        if (this.$route.params.tabIndex) {
            this.tabIndex = this.$route.params.tabIndex
        }
    }
}
</script>

<style>
.admission-act-form .mx-datepicker {
    width: 100% !important;
}

.admission-act-form .mx-input-wrapper {
    width: 100% !important;
}

.admission-act-form .mx-input {
    width: 100% !important;
    height: 35px !important;
}

>>> .print-current-nomenclature-lot-modal .modal-body {
    padding: .75rem 0.5rem;
}

>>> .print-current-nomenclature-lot-modal .modal-header {
    padding: .5rem;
}
</style>

<style lang="sass">
.act-form
    height: 100%

.admission-act-form input, .admission-act-form select
    font-size: 14px !important

.errors-output
    color: #900
    text-align: center
    margin-bottom: 15px
</style>
