<template>
    <div class="correction-actions-input">
        <select v-model="internalValue" class="form-control" @change="onChange">
            <option :value="null"></option>
            <option v-for="option in options" :value="option.id">
                {{ option.name }}
            </option>
        </select>
    </div>
</template>

<script>
import {CORRECTION_ACTS_ENDPOINT} from '@utils/endpoints';

export default {
    name: "CorrectionActionsInput",
    props: {
        value: {},
    },
    data() {
        return {
            internalValue: this.value ? this.value.id : null,
            options: []
        }
    },
    methods: {
        onChange() {
            this.$emit('input', this.options.find(shift => shift.id === this.internalValue))
        },
        load() {
            this.$http.get(CORRECTION_ACTS_ENDPOINT + '/actions').then( response => {
                this.options = response.data.data.data;
            }).catch(response => this.$root.responseError(response, 'Ошибка загрузки статусов актов'));
        }
    },
    mounted() {
        this.load();
    },
}
</script>
