<template>
    <multi-select
        v-model="internalValue"
        :options="options"
        :multiple="false"
        trackBy="id"
        label="name"
        placeholder="Выберите поставщика"
        select-label=""
        selected-label=""
        deselect-label=""
        deselected-label=""
        @input="onChange"
        :max-height="400"
        @search-change="onSearch"
        :show-no-results="false"
        :disabled="disabled"
        :show-labels="false"
        :custom-label="customLabel"
    ></multi-select>
</template>

<script>
    import debounce from 'lodash/debounce';
    import MultiSelect from 'vue-multiselect';
    import { CONTRACTORS_ENDPOINT } from '../../utils/endpoints';

    export default {
        components: { MultiSelect },

        props: {
            value: {},
            disabled: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                internalValue: this.value,
                options: [],
                request: null,
                lastSearch: null
            }
        },

        watch: {
            value() {
                this.internalValue = this.value;
            }
        },

        methods: {
            customLabel( { name, inn } ) {
                return name + ( inn ? ' (' + inn + ')' : '' );
            },

            onChange() {
                this.$emit( 'input', this.internalValue );
            },

            onSearch( search ) {
                if ( search === this.lastSearch ) {
                    return;
                }

                this.options = [];
                this.lastSearch = search;

                this.getContractors( search, this );
            },

            getContractors: debounce( ( search , vm ) => {
                vm.$http
                    .get( CONTRACTORS_ENDPOINT, {
                        params: {
                            sort: 'name',
                            sortDirection: 'asc',
                            limit: 10000
                        },
                        before(request) {

                            // abort previous request, if exists
                            if (this.previousRequest) {
                                this.previousRequest.abort();
                            }

                            // set previous request on Vue instance
                            this.previousRequest = request;
                        }
                    } )
                    .then( response => vm.options = response.data.data.data )
                    .catch( error => {
                        if ( vm.axios.isCancel( error ) ) {
                            console.log( 'Request canceled' );
                        }
                    } );
            }, 250 ),
        },

        mounted() {
            this.getContractors( null, this );
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
