<template>
    <div class="manufacturer-input">
    <multi-select
        :value="internalValue"
        :options="options"
        :multiple="false"
        trackBy="id"
        label="name"
        :placeholder="lastSearch"
        select-label=""
        selected-label=""
        deselect-label=""
        deselected-label=""
        @select="onChange"
        @open="open = true"
        @close="open = false"
        :max-height="400"
        @search-change="onSearch"
        :show-no-results="false"
        :disabled="disabled"
        :show-labels="false"
        :custom-label="customLabel"
        :searchable="true"
        :preserve-search="true"
    ></multi-select>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    import MultiSelect from 'vue-multiselect';
    import {MANUFACTURER_ENDPOINT} from "../../utils/endpoints";

    export default {
        components: { MultiSelect },

        props: {
            value: {},
            disabled: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                internalValue: this.value,
                options: [],
                request: null,
                lastSearch: '',
                open: false
            }
        },

        watch: {
            value() {
                this.internalValue = this.value;
            }
        },

        methods: {
            customLabel( { name } ) {
                return name;
            },

            onChange(event) {
                this.internalValue = event;
                this.$emit( 'input', this.internalValue );
            },

            onSearch( search ) {
                if ( search === this.lastSearch) {
                    return;
                }

                this.options = [];
                this.lastSearch = search;

                if (this.open) {
                    this.internalValue = null;
                    this.$emit('search', this.lastSearch);

                    this.getManufacturers( search, this );
                }
            },

            getManufacturers: debounce( ( search , vm ) => {
                let params = {
                    'orders': {
                        name: 'asc'
                    },
                    limit: 0,
                    without_loading: true,
                };

                if (!!search) {
                    params.filters = {
                        search_name: search
                    };
                }

                vm.$http
                    .get( MANUFACTURER_ENDPOINT, {
                        params,
                        before(request) {

                            // abort previous request, if exists
                            if (this.previousRequest) {
                                this.previousRequest.abort();
                            }

                            // set previous request on Vue instance
                            this.previousRequest = request;
                        }
                    } )
                    .then( response => vm.options = response.data.data.data )
                    .catch(response => this.$root.responseError(response, 'Ошибка!'));
            }, 250 ),
        }
    }
</script>
<style>
    .manufacturer-input .multiselect__placeholder {
        color: black !important;
    }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
