<template>
    <div class="text-left" id="admission-act-nomenclature-list">
        <b-card no-body v-for="item in items" :key="item.id" :bg-variant="item._rowVariant">
            <b-card-header @click="rowClicked(item)">
                <b-row>
                    <b-col cols="6">
                        <b-row>
                            <b-col cols="auto">
                                {{ item.nomenclature.name }}
                            </b-col>
                            <b-col cols="auto">
                                <h5>
                                    <b-badge
                                        v-show="item.nomenclature && item.nomenclature.has_allergen"
                                        variant="warning"
                                        class="mr-2"
                                    >
                                        Аллерген
                                    </b-badge>
                                    <b-badge
                                        v-if="item.nomenclature && item.nomenclature.type"
                                        variant="info"
                                    >
                                        {{ item.nomenclature.type.name }}
                                    </b-badge>
                                </h5>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="6" class="text-right">
                        <h5>
                            <b-badge
                                v-show="expirationDateWarning(item)"
                                variant="warning"
                                class="mr-2"
                            >
                                {{ expirationDateWarning(item) }}
                            </b-badge>
                            <b-badge
                                :variant="isItemValid(item) ? 'success' : 'danger'"
                            >
                                {{ item.count }} {{ item.nomenclature.measureUnit.name }}
                            </b-badge>
                        </h5>
                    </b-col>
                </b-row>
            </b-card-header>
            <b-collapse :id="item.id" accordion="items" v-model="item.collapsed">
                <b-card-body>
                    <b-row class="pt-2">
                        <b-col cols="6">
                            <b-form-group description="Запланированный объем">
                                <b-input-group>
                                    <template #append>
                                        <b-input-group-text>
                                            {{ item.nomenclature.measureUnit.name }}
                                        </b-input-group-text>
                                    </template>
                                    <input
                                        :disabled="true"
                                        min="0"
                                        type="number"
                                        v-model.number="item.incoming_count"
                                        class="form-control"
                                    >
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group description="Поставленный объем">
                                <b-input-group>
                                    <template #append>
                                        <b-input-group-text>
                                            {{ item.nomenclature.measureUnit.name }}
                                        </b-input-group-text>
                                    </template>
                                    <input
                                        :disabled="disabled"
                                        min="0"
                                        type="number"
                                        v-model.number="item.delivered_count"
                                        class="form-control"
                                    >
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="pt-2">
                        <b-col cols="4">
                            <b-form-group description="Вес несоответствия">
                                <b-input-group>
                                    <template #append>
                                        <b-input-group-text>
                                            {{ item.nomenclature.measureUnit.name }}
                                        </b-input-group-text>
                                    </template>
                                    <input
                                        :disabled="true"
                                        type="number"
                                        v-model.number="(item.incoming_count - item.delivered_count).toFixed(2)"
                                        class="form-control"
                                    >
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col cols="4">
                            <b-form-group description="Принятый объем">
                                <b-input-group>
                                    <template #append>
                                        <b-input-group-text>
                                            {{ item.nomenclature.measureUnit.name }}
                                        </b-input-group-text>
                                    </template>
                                    <input
                                        :disabled="true"
                                        type="number"
                                        v-model.number="(item.delivered_count - item.returnCount).toFixed(2)"
                                        class="form-control"
                                    >
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col cols="4">
                            <b-form-group description="Объем возврата">
                                <b-input-group>
                                    <template #append>
                                        <b-input-group-text>
                                            {{ item.nomenclature.measureUnit.name }}
                                        </b-input-group-text>
                                    </template>
                                    <input
                                        v-model.number="item.returnCount"
                                        :disabled="disabled || !item.correctionAction"
                                        min="0"
                                        type="number"
                                        class="form-control">
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <div class="row">
                        <div class="col-5">
                            <b-form-group description="Производитель">
                                <manufacturer-input :key="item.id" v-model="item.manufacturer"
                                                    @search="item.manufacturer = {name: $event}"></manufacturer-input>
                            </b-form-group>
                        </div>
                        <div class="col-5">
                            <b-form-group description="Корректирующее действие">
                                <correction-actions-input :key="item.id" v-model="item.correctionAction"
                                                          @search="item.correctionAction = {name: $event}"></correction-actions-input>
                            </b-form-group>
                        </div>
                        <div class="col-2">
                            <b-form-group description="Температура">
                                <b-input-group>
                                    <b-input-group-prepend>
                                        <b-button @click="setNegativeTemperature(item)">-</b-button>
                                    </b-input-group-prepend>
                                    <input v-model.number="item.admissionTemperature" step="0.1" type="number"
                                           class="form-control">
                                </b-input-group>
                            </b-form-group>

                        </div>
                    </div>
                    <QualityControlPicker :item="item" v-if="allowedQualityControl"/>
                    <div class="row" v-if="!!item.correctionAction">
                        <div class="col-12">
                            <b-form-group description="Комментарий к корректирующему действию">
                                <textarea v-model="item.comment" style="width: 100%" rows="2"
                                          class="form-control"></textarea>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <b-form-group description="Сертификат">
                                <input v-model="item.certificateName" type="text" class="form-control">
                            </b-form-group>
                        </div>
                        <div class="col-6">
                            <b-form-group description="Срок действия сертификата">
                                <date-picker
                                    lang="ru"
                                    type="date"
                                    format="DD.MM.YYYY"
                                    value-type="YYYY-MM-DD"
                                    placeholder="Выберите дату"
                                    v-model="item.certificateExpiresAt"
                                    :key="item.id + '_certificate_expires_at'"
                                    :id="item.id + '_certificate_expires_at'"
                                />
                            </b-form-group>
                        </div>
                    </div>
                    <b-row v-show="item.nomenclature.useNomenclatureLot">
                        <b-col cols="6" style="padding-right: 5px;">
                            <b-form-group description="Изготовлено">
                                <b-input-group>
                                    <date-picker
                                        lang="ru"
                                        type="date"
                                        format="DD.MM.YYYY"
                                        value-type="YYYY-MM-DD"
                                        placeholder="Выберите дату"
                                        v-model="item.manufactureDate"
                                        @input="onManufactureDateInput(item)"
                                        :key="item.id + '_manufacture_date'"
                                        :id="item.id + '_manufacture_date'"
                                    />
                                </b-input-group>
                            </b-form-group>
                        </b-col>

                        <b-col cols="6" style="padding-left: 5px;">
                            <b-form-group>
                                <template #description>
                                    <template>
                                        <span>{{ expirationDateDescription(item) }}</span>
                                        <span class="pull-right">{{ additionalExpirationDateDescription(item) }}</span>
                                    </template>
                                </template>
                                <b-input-group>
                                    <template v-if="item.expirationDateView">
                                        <input v-model="item.expirationDays"
                                               :disabled="disabled"
                                               v-if="selectedTermType === 'day'"
                                               @input="setExpDateByDays(item)"
                                               type="number"
                                               class="form-control"
                                        >
                                        <input v-model="item.expirationMonths"
                                               :disabled="disabled"
                                               v-if="selectedTermType === 'month'"
                                               @input="setExpDateByMonths(item)"
                                               type="number"
                                               class="form-control"
                                        >
                                        <input v-model="item.expirationYears"
                                               :disabled="disabled"
                                               v-if="selectedTermType === 'year'"
                                               @input="setExpDateByYears(item)"
                                               type="number"
                                               class="form-control"
                                        >
                                        <select v-model="selectedTermType" :disabled="disabled"
                                                style="background: white;border-radius: 0 4px 4px 0;">
                                            <option v-for="type in termTypes" :value="type.name">
                                                {{ type.label }}
                                            </option>
                                        </select>
                                    </template>
                                    <date-picker
                                        v-else
                                        lang="ru"
                                        type="date"
                                        format="DD.MM.YYYY"
                                        value-type="YYYY-MM-DD"
                                        placeholder="Выберите дату"
                                        v-model="item.expirationDate"
                                        @input="setExpDaysByDate(item)"
                                        :key="item.id + '_expiration_date'"
                                        :id="item.id + '_expiration_date'"
                                    />
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="6" style="text-align: left">
                            <b-button style="color: white;" :disabled="disabled" variant="info"
                                      @click="$emit('copy', item)">
                                <i class="fas fa-plus"></i> еще партия
                            </b-button>
                            <b-button :disabled="disabled" variant="danger" @click="$emit('remove', item)" class="ml-2"
                                      style="color: white;">
                                <i class="fas fa-times" style="width: 14px;"></i> удалить партию
                            </b-button>
                        </b-col>
                        <b-col cols="6" style="text-align: right">
                            <b-form-checkbox
                                v-model="item.expirationDateView"
                                switch
                                size="lg"
                            >
                                {{ item.expirationDateView ? ' Дней' : ' Срок' }}
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-collapse>
        </b-card>
    </div>
</template>

<script>
import NomenclatureListBase   from '@components/_common/NomenclatureListBase'
import moment                 from 'moment'
import DatePicker             from 'vue2-datepicker';
import BarcodeRepository      from "@utils/BarcodeRepository";
import ManufacturerInput      from "@components/_common/ManufacturerInput";
import CorrectionActionsInput from "./CorrectionActionsInput";
import MultiSelect            from 'vue-multiselect';
import QualityControlPicker   from './QualityControlPicker';
import {ROLE_STOCKMAN_SENIOR} from '@utils/Roles';

const WORK_POSITION_TECHNOLOGIST = 'technologist';
const WORK_POSITION_CHIEF_TECHNOLOGIST = 'chief_technologist';
const WORK_POSITION_SENIOR_TECHNOLOGIST = 'senior_technologist';

export default {
    name: "nomenclature-list",
    extends: NomenclatureListBase,
    components: {
        DatePicker,
        ManufacturerInput,
        CorrectionActionsInput,
        MultiSelect,
        QualityControlPicker,
    },
    props: {
        storage: {
            type: Object,
            default() {
                return {}
            }
        },
        items: {
            type: Array
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        allowedQualityControl() {
            return this.$auth.user().roles.some((role) => [ROLE_STOCKMAN_SENIOR].includes(role))
                || (
                    this.$auth.user().current_work_position
                    && [
                        WORK_POSITION_TECHNOLOGIST,
                        WORK_POSITION_CHIEF_TECHNOLOGIST,
                        WORK_POSITION_SENIOR_TECHNOLOGIST
                    ].includes(this.$auth.user().current_work_position.key)
                );
        }
    },
    watch: {
        items() {
            this.items.forEach(item => {
                if (item.manufactureDate) {
                    if (item.expirationDate) {
                        this.setExpDaysByDate(item)
                    } else if (item.expirationDays) {
                        this.setExpDateByDays(item)
                    }
                }
            })
        },
    },
    data() {
        return {
            termTypes: [
                {name: 'day', label: 'дней'},
                {name: 'month', label: 'мес.'},
                {name: 'year', label: 'лет'}
            ],
            selectedTermType: 'day',
        }
    },
    methods: {
        declOfNum(number, titles) {
            let cases = [2, 0, 1, 1, 1, 2];

            number = Math.abs(number)

            return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
        },
        expirationDateDescription(item) {
            if (item.expirationDateView) {
                switch (this.selectedTermType) {
                    case 'day':
                        return 'Годен дней'
                    case 'month':
                        return 'Годен месяцев'
                    case 'year':
                        return 'Годен лет'
                }
            } else {
                return 'Годен до'
            }
        },
        expirationDateWarning(item) {
            if (item.manufactureDate && item.expirationDate) {
                let duration = moment(item.expirationDate).diff(moment(item.manufactureDate), 'years')
                if (duration > 5) {
                    return 'Внимание! Срок годности больше 5 лет!'
                }
            }
            return null
        },
        additionalExpirationDateDescription(item) {
            if (item.manufactureDate && item.expirationDate) {
                if (item.expirationDateView) {
                    return `(до ${moment(item.expirationDate).format('DD.MM.YY')})`
                } else {
                    switch (this.selectedTermType) {
                        case 'day':
                            return `(${item.expirationDays} ${this.declOfNum(item.expirationDays, ['день', 'дня', 'дней'])})`
                        case 'month':
                            return `(${item.expirationMonths} ${this.declOfNum(item.expirationMonths, ['месяц', 'месяца', 'месяцев'])})`
                        case 'year':
                            return `(${item.expirationYears} ${this.declOfNum(item.expirationYears, ['год', 'года', 'лет'])})`
                    }
                }
            }

            return ''
        },
        onManufactureDateInput(item) {
            if (!item.manufactureDate) {
                return
            }

            // Посимвольный ввод с клавиатуры провоцирует некорректный пересчет
            if ((new Date(item.manufactureDate)).getFullYear() < 2000) {
                return
            }

            if (item.expirationDate === undefined || item.expirationDate === null || item.expirationDate === '') {
                this.setExpDateByLot(item)
                return
            }

            if (item.expirationDateView) {
                switch (this.selectedTermType) {
                    case 'day':
                        this.setExpDateByDays(item)
                        break
                    case 'month':
                        this.setExpDateByMonths(item)
                        break
                    case 'year':
                        this.setExpDateByYears(item)
                        break
                }
            } else {
                this.setExpDaysByDate(item)
            }
        },
        setExpDateByDays(item) {
            if (item.manufactureDate) {
                item.expirationDays = Math.abs(item.expirationDays);
                this.$set(item, 'expirationDate', moment(item.manufactureDate).add(item.expirationDays, 'days').format('YYYY-MM-DD'))
                this.setExpDaysByDate(item)
            }
        },
        setExpDateByMonths(item) {
            if (item.manufactureDate) {
                item.expirationMonths = Math.abs(item.expirationMonths);
                this.$set(item, 'expirationDate', moment(item.manufactureDate).add(item.expirationMonths, 'months').format('YYYY-MM-DD'))
                this.setExpDaysByDate(item)
            }
        },
        setExpDateByYears(item) {
            if (item.manufactureDate) {
                item.expirationYears = Math.abs(item.expirationYears);
                this.$set(item, 'expirationDate', moment(item.manufactureDate).add(item.expirationYears, 'years').format('YYYY-MM-DD'))
                this.setExpDaysByDate(item)
            }
        },
        setExpDateByLot(item) {
            if (item.manufactureDate) {
                // Ищем последнюю партию для этой же номенклатуры
                BarcodeRepository.findLastLotByNomenclature(item.nomenclature.id)
                    .then(lot => {
                        if (lot) {
                            let duration = moment(lot.expiration_date).diff(moment(lot.manufacture_date), 'days')

                            if (duration !== 0) {
                                this.$set(item, 'expirationDate', moment(item.manufactureDate).add(duration, 'days').format('YYYY-MM-DD'))

                                this.setExpDaysByDate(item)
                            }
                        } else {
                            console.log('lot not found')
                        }
                    })
            }
        },
        setExpDaysByDate(item) {
            if (item.manufactureDate) {
                this.$set(item, 'expirationDays', moment(item.expirationDate).diff(moment(item.manufactureDate), 'days'))
                this.$set(item, 'expirationMonths', moment(item.expirationDate).diff(moment(item.manufactureDate), 'months'))
                this.$set(item, 'expirationYears', moment(item.expirationDate).diff(moment(item.manufactureDate), 'years'))
            }
        },
        isItemValid(item) {
            return item.count
                && (!item.nomenclature.useNomenclatureLot || item.expirationDate && item.manufactureDate);
        },
        setNegativeTemperature(item) {
            if (item.admissionTemperature > 0) {
                item.admissionTemperature *= (-1);
            }
        },
    },
}
</script>


<style>
#admission-act-nomenclature-list input {
    font-size: small !important;
}

#admission-act-nomenclature-list .card.bg-secondary {
    color: white !important;
}

#admission-act-nomenclature-list .card.bg-secondary .text-muted {
    color: white !important;
}

#admission-act-nomenclature-list .valid-item {
    background: #28a745;
    border-radius: 5px;
    padding: 3px 5px;
    color: white;
}

#admission-act-nomenclature-list .not-valid-item {
    background: rgba(236, 46, 64, 0.91);
    border-radius: 5px;
    padding: 3px 5px;
    color: white;
}

#admission-act-nomenclature-list .mx-datepicker {
    width: 100% !important;
}

#admission-act-nomenclature-list .mx-input-wrapper {
    width: 100% !important;
}

#admission-act-nomenclature-list .mx-input {
    width: 100% !important;
    height: 35px !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
